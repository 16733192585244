import React, { useState, useEffect } from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"

const TermsAndConditions = ({ data }) => {

    const [metaTags, setMetaTags] = useState([])
    const [pageTile, setPageTitle] = useState([])
    const [pageDescription, setPageDescription] = useState([])

    const formatData = () => {
        if(data) {
            let dataLevelOne = data?.allNodeTemplatePages?.edges?.[0]?.node
            let meta = []
            setMetaTags(meta)
            let title = dataLevelOne?.title || 'NA'
            setPageTitle(title)
            let description = dataLevelOne?.relationships?.components?.[0]?.relationships?.components?.[0]?.text?.processed || 'No Data Found'
            setPageDescription(description)
        }
    }

    useEffect(() => {
        formatData()
    },[data])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        "/assets/css/common.css", 
                        "/assets/css/accessibility.css"
                    ],
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                {/* <section className="breadcrumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                    <li><a href="javascript:;">Home</a></li>
                                    <li><span> Terms and Conditions</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="section_bg pt-first-section pb-section">
                    <div className="container">
                        <div className="section-head">
                            <h1>{pageTile}</h1>
                        </div>              
                    </div>
                    <div className="container">
                        <div className="content_card_box bg-white">
                            <div className="inner_card_content" dangerouslySetInnerHTML={{ __html: pageDescription }} />
                        </div>
                    </div> 
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
query termsAndConditions {
    allNodeTemplatePages(
      filter: {path: {alias: {regex: "/terms-and-conditions$/"}}}
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphSectionTermsAndConditions
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectionTermsAndConditions on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphTitleAndDescriptionTermsAndConditions
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionTermsAndConditions on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
    field_text {
      processed
    }
}
`

export default TermsAndConditions